export default function ErrorComponent() {
    return (
        <div className="flex items-center justify-center h-screen">
            <div className="text-center">
                <img src="../asset/image/error_image.png" alt="에러 이미지" className="w-32 h-32 mx-auto"/>
                <h1 className="text-2xl font-bold">서비스 이용에 불편을 드려 죄송합니다.</h1>
                <h1 className="text-2xl font-bold">새로고침 이후에도 문제가 발생할 시 문의 바랍니다.</h1>
            </div>
        </div>
    )
}
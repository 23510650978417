import React, {Suspense, useContext, useEffect, useState} from 'react'

import Navbar from "../components/Navbar";
import AuthContext from "../auth/AuthContext";
import mainPhoto from "../asset/image/mainWheelchair.jpeg";
import swim2 from "../asset/image/HeaderSection/swim2.jpeg";
import track2 from "../asset/image/HeaderSection/track2.jpeg";
import Loding from "../components/Loding";
import MainPopUp from "../components/MainPopUp";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MainFirstPage from "../components/MainFirstPage";

const MainNotification = React.lazy(() => import('../components/MainNotification'));
const MainAthletic = React.lazy(() => import('../components/MainAthletic'));
const MainPhotoSlide = React.lazy(() => import('../components/MainPhotoSlide'));
const Footer = React.lazy(() => import('../components/Footer'));


export default function MainPage(){


    const [userData, setUserData] = useState(null);


    const context = useContext(AuthContext);

    useEffect(() => {
        // 로컬 스토리지에서 토큰을 가져오기
        const token = localStorage.getItem('token');

        if (token) {
            // 토큰이 있다면 /member/current 엔드포인트로 사용자 데이터 요청
            fetch('/member/current', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        // 사용자 데이터가 성공적으로 받아와졌을 때 state에 저장
                        setUserData(data.data);
                        context.login({userData: data.data});
                    } else {
                        // 토큰이 유효하지 않거나 오류가 발생한 경우 로그아웃 또는 다른 처리 수행
                        context.logout();
                        console.error('Failed to fetch user data');
                    }
                })
                .catch((error) => {
                    console.error('Error during user data fetch:', error);
                });
        }
    }, []);

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        pauseOnHover: false,
    };


    return(
        <>
            <Navbar navClass="nav-light"/>

            <Slider {...settings}>
                <div>
                    <MainFirstPage backImg={mainPhoto} title={`스포츠의 힘으로\n 인생의 무한한 가능성을 열다.`} subTitle={`Open your boundless possibilities in life with the power of sports`}/>
                </div>
                <div>
                    <MainFirstPage backImg={track2} title={`하남시장애인체육회`} subTitle={`HANAM PARALYMPIC COUNCIL`}/>
                </div>
                <div>
                    <MainFirstPage backImg={swim2} title={`한계 없는 열정\n 장애인 스포츠의 힘을 믿습니다.`} subTitle={`하남시장애인체육회`}/>
                </div>
            </Slider>



            <Suspense fallback={<Loding />}>

            <MainPopUp />

            <MainNotification />

            <MainAthletic />

            <MainPhotoSlide />


            <section className="relative py-2 xs:hidden" style={{backgroundColor : "#F6F6F6"}}>
                <div className="container relative" style={{color : "gray"}}>
                    <span>개인정보처리방침 | </span>
                    <span>이메일무단수집거부 | </span>
                    <span>사이트맵</span>
                </div>
            </section>

            <Footer/>
            </Suspense>
        </>
    )
}
export const fetchPopupsBySize = async (page, size) => {
    try {
        const response = await fetch(`/popup/isShow/page?page=${page}&size=${size}`);
        if (!response.ok) {
            alert('팝업 리스트를 불러오지 못했습니다.');
        }

        return await response.json().then(data => data.data.content);
    } catch (error) {
        console.error('Error fetching popup list:', error);
    }
}

export const fetchPopupList = async () => {
    try {
        const response = await fetch('/popup/isShow');
        if (!response.ok) {
            alert('팝업 리스트를 불러오지 못했습니다.');
        }
        return await response.json().then(data => data.data);
    } catch (error) {
        console.error('Error fetching popup list:', error);
    }
}

export const fetchPopupById = async (id) => {
    try {
        const response = await fetch(`/popup?id=${id}`);
        if (!response.ok) {
            alert('팝업을 불러오지 못했습니다.');
        }

        return await response.json().then(data => data.data);
    } catch (error) {
        console.error('Error fetching popup by id:', error);
    }
}

export const updatePopup = async (id, name, url, isShow, dueDate, size, locationX, locationY) => {
    try {
        const PopupRequestDto = {
            name: name,
            url: url,
            isShow: isShow.value,
            dueDate: dueDate,
            size: size,
            locationX: locationX,
            locationY: locationY,
        }

        const response = await fetch(`/popup/update?id=${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(PopupRequestDto),
        });

        if (!response.ok) {
            alert('팝업 수정에 실패했습니다.');
        }

        alert("팝업 수정이 완료되었습니다.");

        window.location.href = '/popup-list';
    } catch (error) {
        console.error('Error updating popup:', error);
    }
}
import React, { Component } from 'react';
import ErrorComponent from "./ErrorComponent";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // 에러가 발생하면 상태를 업데이트하여 에러 화면을 렌더링합니다.
        this.setState({ hasError: true });
        // 여기서 에러를 로깅하거나 기타 작업을 수행할 수 있습니다.
        console.error('Error caught by ErrorBoundary:', error, info);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorComponent />;
        }
        // 에러가 없다면 자식 컴포넌트를 렌더링합니다.
        return this.props.children;
    }
}

export default ErrorBoundary;

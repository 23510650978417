import React, {useEffect, useState} from "react";
import {fetchPopupList} from "../api/Popup";

export default function MainPopUp() {

    const [popups, setPopups] = useState([])

    useEffect(() => {
        fetchPopupList().then(data => {
            setPopups(data);
        });
    },[]);

    return (
        <>
            {
                popups ?
                    popups.map((popup, index) => {
                        if (localStorage.getItem(popup.name) > new Date().getDate()) {
                            return null;
                        }else{
                            localStorage.removeItem(popup.name);
                        }
                        return (
                            <div key={index} className={`fixed w-[${popup.size}%] top-[${popup.locationY}px] left-[${popup.locationX}px] z-1`}>
                                <div style={{ width: 'fit-content' }}>
                                    <img src={popup.url} alt={popup.name}/>
                                    <div className="bg-black text-white px-2 flex justify-between items-center">
                                        <button onClick={() => {
                                            localStorage.setItem(popup.name, new Date().getDate() + 1);
                                            document.querySelector('.fixed').style.display = 'none';
                                        }}>하루 닫기</button>
                                        <button onClick={() => {
                                            document.querySelector('.fixed').style.display = 'none';
                                        }}>닫기</button>
                                    </div>
                                </div>
                            </div>



                        )
                    })
                    : null
            }
        </>
    )
};
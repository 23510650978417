import CountUp from "react-countup";
import React, {useEffect} from "react";
import {FaAngleDoubleDown} from "react-icons/fa";

export default function MainFirstPage({backImg, title, subTitle}) {

    useEffect(() => {
        const addFadeInClass = () => {
            const element = document.getElementById('down');
            element.classList.add('fade-in');
        };

        const timer = setInterval(addFadeInClass, 3000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <>
            <section className="relative md:flex md:h-screen items-center md:py-0 pt-36 pb-56 bg-no-repeat bg-center bg-cover custom-gradient"
                     style={{backgroundImage: `linear-gradient(to top, #1e293b, rgba(30, 41, 59, 0.3)), url(${backImg})`}}>
                <div className="container relative fade-in">
                    <div className="grid grid-cols-1 mt-10">
                        <h4 className={`lg:leading-normal leading-normal text-white mb-5 font-bold lg:text-5xl md:text-3xl sm:text-2xl xs:text-2xl xs:mt-36 `}>
                            {title.split('\n').map((line, index) => (
                                <span key={index}>
                                {line}
                                    <br />
                            </span>
                            ))}
                        </h4>
                        <p className={`text-slate-400 lg:text-3xl md:text-3xl sm:text-2xl xs:text-xl xs:pb-10 `}>
                            {subTitle}
                        </p>
                    </div>

                    <div className="relative top-40 text-white text-5xl text-center">
                        <FaAngleDoubleDown id="down" className="inline-block move-down"/>
                    </div>

                </div>

                <div className="container absolute bottom-0 start-0 end-0 xs:hidden fade-in">
                    <div className="grid grid-cols-1">
                        <div className="p-6 border-t border-dashed border-white/30">
                            <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 gap-[30px] xs:hidden">
                                <div className="counter-box flex items-center justify-center">
                                    <h1 className="text-3xl font-bold text-white">10년</h1>
                                    <h5 className="counter-head text-xs font-semibold text-white/40 ms-2">2013년 하남시<br/>장애인 체육회 설립</h5>
                                </div>

                                <div className="counter-box flex items-center justify-center">
                                    <h1 className="text-3xl font-bold text-white"><CountUp className="counter-value" start={1} end={30} />+</h1>
                                    <h5 className="counter-head text-xs font-semibold text-white/40 ms-2">장애인 체육대회 개최</h5>
                                </div>

                                <div className="counter-box flex items-center justify-center">
                                    <h1 className="text-3xl font-bold text-white"><CountUp className="counter-value" start={1} end={80} />+</h1>
                                    <h5 className="counter-head text-xs font-semibold text-white/40 ms-2">생활 체육<br />프로그램 진행</h5>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
